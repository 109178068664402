import { Button, Col, Layout, Row } from "antd"
import React from "react"
import Login from "./login"
import Sidebar from "./sidebar"
import useAuth from "./use-auth"

const { Content, Footer, Header } = Layout

export default ({ children }) => {
  const auth = useAuth()
  return (
    <Login>
      <Layout style={{ minHeight: "100vh" }}>
        <Sidebar />
        <Layout>
          <Header style={{ background: "#fff", padding: "0 40px" }}>
            <Row type="flex" justify="space-between">
              <Col>
                <Button onClick={() => auth.logout()}>Sign out</Button>
              </Col>
            </Row>
          </Header>
          <Content style={{ padding: "0 40px" }}>{children}</Content>
          <Footer style={{ padding: "24px 40px", textAlign: "right" }}>
            Copyright &copy; {new Date().getFullYear()} My Baby Tools. All
            rights reserved.
          </Footer>
        </Layout>
      </Layout>
    </Login>
  )
}
