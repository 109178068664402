import { Spin } from "antd"
import React, { useEffect, useState } from "react"
import useAuth from "./use-auth"

export default ({ children }) => {
  const auth = useAuth()
  const [isAuthenticated, setAuthenticated] = useState(false)
  useEffect(() => {
    const isAuthenticated = auth.isAuthenticated()
    if (isAuthenticated) {
      setAuthenticated(isAuthenticated)
    } else {
      const { hash, pathname, search } = window.location
      auth.login({
        appState: { redirectUrl: pathname + search + hash },
      })
    }
  })
  return isAuthenticated ? (
    children
  ) : (
    <Spin
      size="large"
      // TODO: P2 conditional message - redirecting on unauthenticated, otherwise authenticating
      tip="Redirecting to login..."
      style={{ maxHeight: "none" }}
    >
      <div style={{ height: "100vh" }} />
    </Spin>
  )
}
