import { Location } from "@reach/router"
import { Layout, Menu } from "antd"
import { Link } from "gatsby"
import React from "react"

const { Sider } = Layout

const Sidebar = () => (
  <Sider breakpoint="lg" collapsedWidth={0}>
    <Location>
      {({ location }) => (
        <Menu mode="inline" theme="dark" selectedKeys={[location.pathname]}>
          <Menu.Item key="/">
            <Link to="/">Dashboard</Link>
          </Menu.Item>
          <Menu.ItemGroup title="Master">
            <Menu.Item key="/customer/">
              <Link to="/customer/">Customer</Link>
            </Menu.Item>
            <Menu.Item key="/supplier/">
              <Link to="/supplier/">Supplier</Link>
            </Menu.Item>
            <Menu.Item key="/marketplace/">
              <Link to="/marketplace/">Marketplace</Link>
            </Menu.Item>
            <Menu.Item key="/item-category/">
              <Link to="/item-category/">Item Category</Link>
            </Menu.Item>
            <Menu.Item key="/item/">
              <Link to="/item/">Item</Link>
            </Menu.Item>
          </Menu.ItemGroup>
          <Menu.ItemGroup title="Transaction">
            <Menu.Item key="/stock-in/">
              <Link to="/stock-in/">Stock In</Link>
            </Menu.Item>
            <Menu.Item key="/stock-out/">
              <Link to="/stock-out/">Stock Out</Link>
            </Menu.Item>
          </Menu.ItemGroup>
          <Menu.ItemGroup title="Report">
            <Menu.Item key="/report/stock/">
              <Link to="/report/stock/">Stock</Link>
            </Menu.Item>
          </Menu.ItemGroup>
        </Menu>
      )}
    </Location>
  </Sider>
)

export default Sidebar
